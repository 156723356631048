/*
 * @Author: 月魂
 * @Date: 2022-10-08 14:48:50
 * @LastEditTime: 2022-10-11 16:49:29
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong\src\locales\langs\fr.js
 */
export default {
  link: {
    preface: 'Avant-propos',
    history: 'Historique',
    warriors: `Le passé et le présent de l'Armée de terre cuite`,
    ancient: 'Arts et artisanats anciens',
    culture: 'Ingéniosités chinoise et occidentale',
    visit: 'Le voyage dans le temps',
    creation: 'Le passé engendre le présent',
    game: 'Rythme original et merveilleux',
  },
  footer: {
    left: {
      title: 'Организатор:',
      item1: 'Амбасада Народне Републике Кине у Републици Србији',
      item2: 'Одељење за културу и туризам провинције Шанси',
    },
    right: {
      title: 'Спонзор:',
      item: 'Компанија за међународни културни развој Шанси Венји'
    },
  }
}
